import React, {
  useEffect, createContext, useState, useContext, useCallback, useRef, use
} from 'react';
import { app } from '@microsoft/teams-js';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';

import { getAuth, setAuth } from '@services/identity.service';
import { getGuestLoginToken } from '@services/email-auth.service';
import { createTeamsUserPlatform } from '@services/youtube-platform.service';

import { TeamsDataContext } from '@components/context/TeamsContext';

import { isMicrosoftTeamsApp, isMeetingContext } from '@teams-app/helpers';
import Loader from '@atoms/Atom/Loader';

export const MicrosoftTeamsDataContext = createContext({});

export const MicrosoftTeamsDataProvider = ({ children }) => {
  const {
    loading = true,
    theme = {},
    themeString = '',
    teamsUserCredential = {}
  } = useTeamsUserCredential({
    initiateLoginEndpoint: process.env.NEXT_PUBLIC_TEAMS_APP_START_LOGIN_PAGE_URL,
    clientId: process.env.NEXT_PUBLIC_TEAMS_APP_CLIENT_ID,
  });

  const { teamContext, initTeam, user } = useContext(TeamsDataContext);

  const [runningContext, setRunningContext] = useState();
  const [userContext, setUserContext] = useState();
  const [meetingContext, setMeetingContext] = useState();
  const [hostClientType, setHostClientType] = useState();
  const [teamsPlatform, setTeamsPlatform] = useState();
  const [template, setTemplate] = useState();

  const initializeTeamsSdk = async () => {
    try {
      await app.initialize();

      app.notifySuccess();

      updateRunningContext();
      updateUserContext();
      updateMeetingContext();
      updateHostClientType();
    } catch (error) {
      // TODO: Retry if the SDK fails to initialize
      console.log('Failed to initialize teams sdk', error);
    }
  };

  const isLoggedIn = () => user && user.user_role !== 'guest';

  const getGreetings = () => {
    if (isLoggedIn()) {
      let name = user?.email?.split('@')[0];
      name = name[0].toUpperCase() + name.slice(1);
      return `Hi ${name} 👋🏽`;
    }
    return 'Hi Guest 👋🏽';
  };

  const initGuest = async () => {
    const data = await getGuestLoginToken({ email: 'guest@streamalive.com' });
    if (data.status) {
      setAuth(data.entity[0]);
      initTeam();
    }
  };

  const updateUserContext = () => {
    app.getContext().then((context) => {
      setUserContext(context.user);
    });
  };

  const updateRunningContext = () => {
    app.getContext().then((context) => {
      setRunningContext(context.page.frameContext);
    });
  };

  const updateMeetingContext = async () => {
    app.getContext().then((context) => {
      setMeetingContext({
        secStreamId: context.meeting.id,
        chatId: context.chat.id,
        title: 'Teams Meeting'
      });
    });
  };

  const updateHostClientType = () => {
    app.getContext().then((context) => {
      setHostClientType(context.app.host.clientType);
    });
  };

  const getTeamsUserId = () => {
    if (isLoggedIn()) return user.id;
    return `${userContext.id}`;
  };

  const setupTeamsUserPlatform = async () => {
    const userId = getTeamsUserId();
    const platformId = teamsPlatform.id;
    await createTeamsUserPlatform({ userId, platformId });
  };

  useEffect(() => {
    isMicrosoftTeamsApp() && initializeTeamsSdk();
  }, []);

  useEffect(() => {
    if (isMicrosoftTeamsApp() && !getAuth()) {
      initGuest();
    }
  }, [teamContext]);

  useEffect(() => {
    if (isMeetingContext(runningContext)) {
      updateUserContext();
      updateMeetingContext();
    }
  }, [runningContext]);

  useEffect(() => {
    if (!!teamsPlatform && user && userContext) {
      setupTeamsUserPlatform();
    };
  }, [teamsPlatform, user, userContext]);

  if (isMicrosoftTeamsApp() && loading) return <Loader showMessages={false} />;

  return (
    <MicrosoftTeamsDataContext.Provider
      value={{
        loading,
        theme,
        themeString,
        teamsUserCredential,
        setTeamsPlatform,
        isLoggedIn,
        getGreetings,
        initGuest,
        teamsPlatform,
        setMeetingContext,
        template,
        setTemplate,
        userContext,
        runningContext,
        meetingContext,
        hostClientType
      }}
    >
      {children}
    </MicrosoftTeamsDataContext.Provider>
  );
};
