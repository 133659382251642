import axios from 'axios';
import { getAuth, getCurrentTeamToken } from './identity.service';

const _getAuthHeaders = (contentType = 'application/json') => {
  const auth = getAuth();
  const teamToken = getCurrentTeamToken();
  return {
    'content-type': contentType,
    'x-access-token': auth?.token,
    'x-team-token': teamToken
  };
};

const _handleResponse = (response, resolve, reject, rejectFalseResponse) => {
  if (response && response.data) {
    if (rejectFalseResponse && response.data?.status === false) {
      reject(response.data);
    }
    resolve(response.data);
  }
};

export const postWithOutAuth = (url, entity, usernameHeader = null, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  let headers = {};
  if (usernameHeader) {
    headers = {
      'content-type': 'application/json',
      'x-username': usernameHeader
    };
  }
  axios.post(url, entity, { headers }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const postWithAuth = (url, entity, contentType = 'application/json', rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.post(url, entity, { headers: _getAuthHeaders(contentType) }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const getWithOutAuth = (url, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.get(url).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const getWithAuth = (url, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.get(url, { headers: _getAuthHeaders() }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const deleteWithOutAuth = (url, entity, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.delete(url, {
    data: entity
  }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const putWithAuth = (url, entity, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.put(url, entity, { headers: _getAuthHeaders() }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const patchWithOutAuth = (url, entity, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.patch(url, entity).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const patchWithAuth = (url, entity, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.patch(url, entity, { headers: _getAuthHeaders() }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const deleteWithAuth = (url, entity, rejectFalseResponse = false) => new Promise((resolve, reject) => {
  axios.delete(url, { headers: _getAuthHeaders(), data: entity }).then((response) => {
    _handleResponse(response, resolve, reject, rejectFalseResponse);
  }).catch((ex) => {
    reject(ex);
  });
});

export const getWithAuthServerSide = (url, auth, teamToken) => {
  const headers = {
    'content-type': 'application/json',
    'x-access-token': auth.token
  };
  if (teamToken) {
    headers['x-team-token'] = teamToken;
  }
  return new Promise((resolve, reject) => {
    axios.get(url, { headers }).then((response) => {
      if (response && response.data) {
        resolve(response.data);
      }
    }).catch((ex) => {
      reject(ex);
    });
  });
};

export const postWithAuthServerSide = (url, auth, teamToken = null, entity = {}, usernameHeader) => {
  const headers = {
    'content-type': 'application/json',
    'x-access-token': auth.token
  };
  if (teamToken) {
    headers['x-team-token'] = teamToken;
  }
  if (usernameHeader) {
    headers['x-username'] = usernameHeader;
  }
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify(entity)
    }).then(async (res) => {
      try {
        const response = await res.json();
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }).catch((ex) => {
      reject(ex);
    });
  });
};
