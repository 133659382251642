import React, { createContext, useState, useContext } from 'react';
const PopupContext = createContext();
export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [streamId, setStreamId] = useState(null);
  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);
  const [fileKeyNames, setFileKeyName] = useState([]); // firebase keys

  const updateStreamId = (id) => setStreamId(id);
  return (
    <PopupContext.Provider value={{ isPopupVisible, showPopup, hidePopup, streamId, updateStreamId, fileKeyNames, setFileKeyName }}>
      {children}
    </PopupContext.Provider>
  );
};
export const usePopupContext = () => {
  return useContext(PopupContext);
};
