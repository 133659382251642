import React, {
  useContext
} from 'react';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import router from 'next/router';
import Avatar from '@atoms/Avatar';
import Button from '@atoms/Button.new';

import {
  Dropdown,
  DropdownMenu,
  DropdownTrigger,
  DropdownItem
} from '@atoms/new/dropdown';

import { TeamsDataContext } from '@components/context/TeamsContext';
import { ZoomDataContext } from '@zoom-app/components/contexts/ZoomContext';
import ExternalLink from '@zoom-app/atoms/ExternalLink';
import { signupLink } from '@services/utils.service';

const DropdownDestination = (props) => {
  const {
    currentTeam,
    user
  } = useContext(TeamsDataContext);

  const { isLoggedIn } = useContext(ZoomDataContext);

  if (!currentTeam) {
    return <></>;
  }

  return (
    <div id="DropdownDestination" className="">
      <div className='flex'>
        {isLoggedIn() ? (
          <Dropdown
            placement="top-end"
            triggerScaleOnOpen={false}
            disableAnimation
          >
            <DropdownTrigger>
              <div>
                <Avatar
                  w='8'
                  h='8'
                  name={user?.email || 'Guest'}
                  isProfile={true}
                />
              </div>
            </DropdownTrigger>
            <DropdownMenu
              itemClasses={{
                base: 'hover:bg-red-500',
              }}
              onAction={(id) => {
                // console.log('Hello world');
              }}
              aria-label="Dropdown menu for list of presentations"
              selectionMode="none"
              topContent={
                <div
                  className="flex items-center justify-center w-full py-2 px-4"
                >
                  {user.user_role === 'guest' ? 'Guest Mode' : user.email}
                </div>
              }
            >

              <DropdownItem
                key="Logout"
                textValue="Logout"
                onAction={() => props.logout()}
                startContent={<LogoutRoundedIcon />}
              >
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <div className="flex">
            <div
              className="ml-2 w-[154px] md:w-fit"
              onClick={() => { router.push(`/zoom-app/login?${props.nextParam}`); }}
            >
              <Button
                variant="tertiary"
                fullWidth
              >
                Login
              </Button>
            </div>
          </div>
        )}
      </div >
    </div >
  );
};

export default DropdownDestination;
