/* eslint-disable indent */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { handleKeyStrokes } from '@services/utils.service';
import { pushDataLayerForEvent } from '@lib/gtag';
import Card from '../atoms/Card';
import Heading from '../atoms/Heading';
import Image from '../atoms/Image';
import { Skeleton } from '@nextui-org/react';

export const platformImages = {
  zoom: '/images/Zoom.svg',
  teams: '/images/teams-logo.svg',
  youtube: '/images/youtube-icon.svg',
  ipa: '/images/in_person.svg',
  meet: '/images/meet-icon.svg',
  twitch: '/images/twitch-icon.svg',
  'other-online-events': '/images/OtherOnlinePlatforms.svg',
  hybrid: '/images/hybrid.svg',
  vimeo: '/images/vimeo-icon.svg',
  webex: '/images/webex-icon.svg',
  linkedin: '/images/linkedin-icon.svg',
  restream: '/images/restream.svg',
  streamyard: '/images/streamyard.png',
  airmeet: '/images/airmeet.svg',
  kick: '/images/kick.png'
};

const CardList = ({
  stream,
  totalStream,
  fetchMoreData,
  goToFantasticPage,
  page,
  isLoading
}) => <>
      <div className='relative mb-6 flex flex-col w-full overflow-hidden border-1 border-grey-200 rounded-lg bg-white'>
        <div className='flex items-center p-3 border-b-1 border-grey-200'>
          <div className='flex items-center w-full'>
            <div className='text-xs  font-semibold text-grey-700 uppercase max-w-96'>Stream</div>
          </div>
          <div className='flex items-center'>
            <div className='text-xs text-grey-700 uppercase  font-semibold  w-44'>Time</div>
            <div className='text-xs text-grey-700 uppercase  font-semibold  min-w-24'>Date</div>
          </div>
        </div>
        <div className='overflow-y-auto flex-auto' id="scrollableDivTwo">
          {isLoading && <>
            <Skeleton className="h-[57px] px-3 border-b-1 border-grey-0" />
            <Skeleton className="h-[57px] px-3" />
          </>}
          {!isLoading && <InfiniteScroll
            dataLength={stream.length}
            hasMore={totalStream >= stream.length}
            loader={totalStream > stream.length && <h4 className='text-grey-700 text-sm w-full text-center py-4 border-t border-grey-200 overflow-hidden'>Loading...</h4>}
            scrollableTarget="scrollableDivTwo"
            next={fetchMoreData}
          >
            {
              stream.map((obj) => <Card
                onClick={() => {
                  const analyticsStreamId = obj.id;
                  pushDataLayerForEvent('analyze analytics', { analyticsStreamId });
                  goToFantasticPage(analyticsStreamId);
                  localStorage.setItem('analyticsScrollId', JSON.stringify({ id: `${analyticsStreamId}`, page }));
                }}
                tabIndex={2}
                onKeyDown={handleKeyStrokes}
                key={obj.id} id={obj.id}
                bgColor={'bg-white'}
                className={'p-3 flex shadow-none border-grey-200 border-t-1 first:border-t-0 border-grey-200 cursor-pointer group rounded-none'}
                background="bg-none">
                <div className='flex items-center w-full pr-2 text-grey-900 group-hover:text-primary-500'>
                  <Image src={platformImages[obj.platform_type]} width={24} alt={`${obj.platform_type}-icon`} className="mr-3 flex-shrink-0" />
                  <Heading className={'text-sm font-semibold max-w-96 truncate overflow-hidden'}>
                    {obj.title}
                  </Heading>
                </div>
                <div className='flex items-center'>
                  <div className='text-[13px] text-grey-700 font-normal leading-5 text-justify  w-44'>{obj.stream_totalTime}</div>
                  <div className='text-[13px] text-grey-700 font-normal leading-5 text-justify  min-w-24'>{obj.stream_date}</div>
                </div>
              </Card>)
            }
          </InfiniteScroll>}
        </div>
    </div>
  </>;

export default CardList;
