import { useEffect, useState, useMemo } from 'react';

import {
  useQuery,
  useQueries
} from '@tanstack/react-query';

import { invokeZoomAppsSdk } from '@zoom-app/apis';

import { isMeetingContext, isHostOrCoHost, getResourceType } from '@zoom-app/helpers';

import { getZoomResource } from '@services/zoom';

interface useMeetingProps {
  headerContext: any;
  platform: any;
  queryClient: any;
  sdkInitialized: boolean;
};

const STALE_TIME = 60000 * 1;
const useMeeting = ({ headerContext, platform, sdkInitialized }: useMeetingProps) => {
  const [isMeeting, setIsMeeting] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [fetchResource, setFetchResource] = useState(false);
  const [resourceType, setResourceType] = useState<string>();

  const queries = useMemo(() => [
    {
      queryKey: ['meetingUUID'],
      queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingUUID' }),
      enabled: isMeeting && sdkInitialized,
      staleTime: STALE_TIME
    },
    {
      queryKey: ['meetingContext'],
      queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingContext' }),
      enabled: isMeeting && isHost && sdkInitialized,
      staleTime: STALE_TIME
    },
    {
      queryKey: ['joinMeetingUrl'],
      queryFn: () => invokeZoomAppsSdk({ name: 'getMeetingJoinUrl' }),
      enabled: isMeeting && isHost && sdkInitialized,
      staleTime: STALE_TIME
    },
    {
      queryKey: ['runningContext'],
      queryFn: () => invokeZoomAppsSdk({ name: 'getRunningContext' }),
      enabled: isMeeting && isHost && sdkInitialized,
      staleTime: STALE_TIME
    }
  ], [isMeeting, isHost, sdkInitialized]);

  const combinedQueries = useQueries({
    queries,
    combine: (results) => {
      const [meetingUUIDRes, meetingContextRes, joinMeetingUrlRes, runningContextRes] = results;
      const meetingInfo: any = {};

      if (meetingUUIDRes?.data?.meetingUUID) {
        meetingInfo.meetingUUID = meetingUUIDRes.data.meetingUUID;
      }

      if (meetingContextRes?.data?.meetingTopic) {
        meetingInfo.meetingId = meetingContextRes.data.meetingID;
        meetingInfo.title = meetingContextRes.data.meetingTopic;
      }

      if (joinMeetingUrlRes?.data?.joinUrl) {
        meetingInfo.joinUrl = joinMeetingUrlRes.data.joinUrl;
      }

      if (runningContextRes?.data?.runningContext) {
        meetingInfo.runningContext = runningContextRes.data.runningContext;
      }

      return {
        data: meetingInfo,
        isPending: results.some((result) => result.isLoading),
      };
    }
  });

  const { data: apiResource } = useQuery({
    queryKey: ['apiResource', platform?.id],
    queryFn: () => getZoomResource(resourceType || 'meetings', combinedQueries.data.meetingId, { platformId: platform?.id }),
    enabled: fetchResource,
    staleTime: STALE_TIME
  });

  useEffect(() => {
    if (headerContext?.typ && isMeetingContext(headerContext?.typ)) {
      setIsMeeting(true);
      setResourceType(getResourceType(headerContext?.typ));
    }
    if (headerContext?.attendrole && isHostOrCoHost(headerContext?.attendrole)) {
      setIsHost(true);
    }
  }, [headerContext]);

  useEffect(() => {
    if (combinedQueries.data?.meetingId && platform?.is_authorized && resourceType) {
      setFetchResource(true);
    }
  }, [combinedQueries, platform, resourceType]);

  const meeting = { ...combinedQueries?.data };
  if (apiResource?.entity) {
    meeting.apiResource = apiResource?.entity;
  }
  return {
    isMeeting,
    isHost,
    meeting
  };
};

export default useMeeting;
