import { useRouter } from 'next/router';

import { isZoomApp, zoomAppBasePath } from '@zoom-app/helpers';

export const redirectToHomeBase = (router) => {
  if (!isZoomApp()) {
    return router.push('/');
  }
  return router.push(zoomAppBasePath);
};

const useRedirect = () => {
  const router = useRouter();

  const getNextParam = () => {
    return `next=${zoomAppBasePath}`;
  };

  const redirectToHome = () => redirectToHomeBase(router);

  return { redirectToHome, getNextParam };
};

export default useRedirect;
